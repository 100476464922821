class SwClient {
  constructor() {
    this.init()
    window.test = this
    this.server = new BroadcastChannel('jobs-channel')

    this.server.onmessage = ({ data }) => {
      const { payload, type } = data
      this.onPost(type, payload)
    }
  }

  onPost = () => {}

  async init() {
    this.started = true
    this.active = false
    this.sw = await navigator.serviceWorker.ready

    this.active = true
  }

  post(msg) {
    this.server.postMessage(msg)
  }
}

export default new SwClient()
