import { useState } from "react"

import usePreload from "common/hooks/usePreload"

import auth from "api/auth"
import Form from "common/Form/Form"

import User from "./Users.one"

import "./Users.scss"

const config = [
  {
    type: "text",
    label: "Email",
    name: "email",
  },
  {
    type: "text",
    label: "First Name",
    name: "firstName",
  },
  {
    type: "text",
    label: "Last Name",
    name: "lastName",
  },
  {
    type: "text",
    label: "Mobile",
    name: "mob",
  },
  {
    type: "select",
    label: "Role",
    name: "role",
    options: [
      {
        label: "Vartotojas",
        value: "user",
      },
      {
        label: "Brokeris",
        value: "broker",
      },
      {
        label: "Centras",
        value: "callCenter",
      },
      {
        label: "Administratorius",
        value: "admin",
      },
    ],
  },
  {
    type: "text",
    label: "Password",
    name: "password",
  },
]

const Users = () => {
  const [showAdd, setShowAdd] = useState()
  const { loading, result, doRefresh } = usePreload(auth.getUsers)

  const onSubmit = {
    type: "submit",
    onSubmit: async (data) => {
      await auth.register(data)
      doRefresh()
      setShowAdd(false)
    },
  }

  const onDelete = async (user) => {
    await auth.delete(user)
    doRefresh()
  }

  return (
    loading || (
      <section className='settings-page'>
        <header>
          <h1>Users</h1>
          <div>
            <button className='btn' onClick={() => setShowAdd(!showAdd)}>
              Add user
            </button>
          </div>
        </header>

        {showAdd && (
          <div className='settings-page__form'>
            <Form config={[...config, onSubmit]} values={{}} />
          </div>
        )}

        <table>
          <tbody>
            {result.map((item) => (
              <User key={item.email} user={item} onDelete={onDelete} />
            ))}
          </tbody>
        </table>
      </section>
    )
  )
}

export default Users
