import Model from './model/mode.input'

import './Form.scss'

const Form = ({ config, values, readOnly }) => {
  const model = new Model(config, { data: values, readOnly })

  return <div className="form">{model.map((item) => item.render())}</div>
}

export default Form
