import { useState, useEffect } from "react"
import Loader from "common/Loader/Loader"

import "./usePreload.scss"

const isObject = (obj) => obj instanceof Object && !(obj instanceof Array)

const PreLoader = (
  <div className='use-preload'>
    <Loader />
  </div>
)

const usePreload = (asyncFunc = (f) => f, params) => {
  const [loading, setLoading] = useState(PreLoader)
  const [updating, setUpdating] = useState(null)
  const [result, setResult] = useState()

  useEffect(() => {
    const getData = async () => {
      const res = await asyncFunc(params)
      setResult(res)
      setLoading(null)
    }

    getData()
  }, [])

  const doRefresh = async () => {
    setLoading(PreLoader)
    const res = await asyncFunc(params)
    setResult(res)
    setLoading(null)
  }

  const doUpdate = async (p = params) => {
    setUpdating(<div className='use-preload__updating'>{PreLoader}</div>)
    const res = await asyncFunc(p)
    setResult(res)
    setUpdating(null)
  }

  if (isObject(result)) {
    return { ...result, loading, updating, result, doRefresh, doUpdate, setResult }
  }
  return { loading, updating, result, doRefresh, doUpdate, setResult }
}

export default usePreload
