import { Outlet, NavLink } from 'react-router-dom'

import './Settings.scss'

const Settings = () => {
  const activeClass = ({ isActive }) => (isActive ? 'active' : undefined)
  return (
    <div className="page">
      <aside className="page__aside">
        <h2 className="page__header">Settings</h2>
        <ul className="page__menu">
          <li>
            <NavLink className={activeClass} to="/settings/profile">
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink className={activeClass} to="/settings/search">
              Saved search
            </NavLink>
          </li>
          <li>
            <NavLink className={activeClass} to="/settings/push-notifications">
              Push notifications
            </NavLink>
          </li>
        </ul>
      </aside>
      <article className="page__content">
        <Outlet />
      </article>
    </div>
  )
}

export default Settings
