import { makeObservable, observable, action } from 'mobx'

const SEC = 1000
const MIN = 60 * SEC

//-----------------------------------------------------------------------------------------------
const randomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

//-----------------------------------------------------------------------------------------------

class Job {
  constructor(payload) {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve
      this._reject = reject
    })

    const { item, logoUrl, title, delay, timer, repeat, count = 0 } = payload

    this.initial = payload
    this.action = payload.action
    this.item = item
    this.logoUrl = logoUrl
    this.repeat = repeat
    this.count = count + 1
    this.timer = this.normalizeTimer(timer)
    this._timer = timer

    this.defaultDelay = delay || 9 / 60

    this.title = title || item.title || item.address

    makeObservable(this, {
      state: observable,
      duration: observable,
      total: observable,
      count: observable,
      repeat: observable,
      setState: action,
      setDuration: action,
      setTotal: action,
    })
  }

  resolve(data) {
    this._resolve(data)
  }

  createdAt = new Date()
  state = null
  _delay = null
  duration = null
  total = null

  setState(value) {
    this.state = value
  }

  setDuration(value) {
    this.duration = value
  }

  setTotal(value) {
    this.total = value
  }

  normalizeTimer(timer) {
    if (timer) {
      if ([ 'string', 'number' ].includes(typeof timer)) {
        return [ { from: 0, delay: parseFloat(timer) } ]
      }

      if (typeof timer === 'object' && !Array.isArray(timer)) {
        timer = Object.entries(timer).map(([ from, delay ]) => ({ from, delay }))
      }

      return timer.map((v) => ({ ...v, time: parseFloat(v.from.split(':').join('.')) })).sort((a, b) => b.time - a.time)
    }
  }

  findCurrent(time) {
    return this.timer.find((v) => v.time < time) || this.timer[0]
  }

  get delay() {
    return this._delay
  }

  getDelay() {
    const { timer } = this
    let delay

    if (timer) {
      const d = new Date()
      const hour = d.getHours()
      const min = d.getMinutes()

      const time = parseFloat(hour + '.' + min)
      const current = this.findCurrent(time)

      if (current) {
        delay = current.delay

        if (!current.delay) {
          const index = timer.indexOf(current)
          const next = timer[index - 1] || timer[timer.length - 1]

          const [ h, m ] = next.from.split(':')
          d.setHours(h)
          d.setMinutes(m)
          const diffMs = d.getTime() - new Date().getTime()
          const diffMin = Math.ceil(diffMs / MIN)

          delay = diffMin > 0 ? diffMin : diffMin + 24 * 60
        }
      }
    }

    this._delay = delay ? randomInt(delay, delay + 5) : this.defaultDelay
    return this._delay
  }
}

export default Job
