import { useState } from "react"
import { observer } from "mobx-react"

import auth from "api/auth"

import LogoIcon from "Icons/Logo"
import Loader from "common/Loader/Loader"

import "./Login.scss"

const Login = () => {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const onLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(false)
    const res = await auth.login({ email, password }).catch(({ response = {} }) => {
      const { errorMessage = "Something went wrong!" } = response.data || {}

      setError(errorMessage)
      setPassword("")
    })

    setLoading(false)

    if (res) {
      setEmail("")
      setPassword("")
    }
  }

  return (
    <div className='login'>
      <section className='login__box'>
        <aside className='login__aside'>
          <LogoIcon />
        </aside>
        <article className='login__content'>
          <h1>Login</h1>

          <form className='login__form' onSubmit={onLogin}>
            <div className='input'>
              <label>Email</label>
              <input autoFocus type='email' value={email} onChange={({ target }) => setEmail(target.value)} />
            </div>

            <div className='input'>
              <label>Password</label>
              <input type='password' value={password} onChange={({ target }) => setPassword(target.value)} />
            </div>

            {error && (
              <div className='input'>
                <div className='error'>{error}!</div>
              </div>
            )}

            <div className='input'>
              <button type='submit'>Login</button>
            </div>
          </form>

          <div className='desc'>Crawler &copy;2022</div>

          {loading && (
            <div className='login__loading'>
              <div>
                <Loader />
              </div>
            </div>
          )}
        </article>
      </section>
    </div>
  )
}

export default observer(Login)
