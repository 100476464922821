import { useState } from "react"
import { useSearchParams } from "react-router-dom"

import http from "api/http"
import ws from "../../services/ws.service"

import "./ServerImage.scss"

const ServerImage = () => {
  const [searchParams] = useSearchParams()

  const encodedUrl = searchParams.get("url")
  const url = encodedUrl ? atob(encodedUrl) : "https://www.aruodas.lt"

  const [last, setLast] = useState({ url })

  ws.onPost = (payload) => {
    setLast(payload)
  }

  const open = () => {
    ws.send({ type: "puppeteer/open", url })
  }

  const close = () => {
    ws.send({ type: "puppeteer/close", url })
  }

  const getPos = (e) => {
    var rect = e.target.getBoundingClientRect()
    var x = e.clientX - rect.left //x position within the element.
    var y = e.clientY - rect.top //y position within the element.

    ws.send({ type: "puppeteer/click", x, y })
    console.log("Left:", x, "Top: ", y)
  }

  return (
    <div className='test'>
      <div className='test__nav'>
        <h1>Puppeteer</h1>
        <button className='btn warning' onClick={() => open()}>
          Open
        </button>
        <button className='btn cancel' onClick={() => close()}>
          Close
        </button>

        <input value={url} />
      </div>

      <section>
        <pre>{JSON.stringify(last, null, 2)}</pre>

        <div className='image'>
          {last.timestamp && (
            <img onClick={getPos} alt='Browser' src={http.baseURL + "/image?time=" + last.timestamp} />
          )}

          {/* <img src='http://localhost:8000/onyx/v1/image/1698862762935.jpg' /> */}
        </div>
      </section>
    </div>
  )
}

export default ServerImage
