import { useState } from 'react'
import { observer } from 'mobx-react'

import Spinner from 'Icons/spinner/Spinner'

import './Submit.scss'

const Submit = ({ model }) => {
  const [ loading, setLoading ] = useState()
  const { values, config, readOnly } = model

  return !readOnly ? (
    <div className="input input-submit">
      <div className="input-submit__action">
        <button
          disabled={loading}
          className="btn"
          onClick={async () => {
            setLoading(true)
            await config.onSubmit(values, model)
            setLoading(false)
          }}
        >
          Submit {loading && <Spinner />}
        </button>
      </div>
    </div>
  ) : null
}

export default observer(Submit)
