import { useRef, useEffect, useState } from 'react'

import swClient from 'ServiceWorker/ServiceWorker.client'

const useSW = () => {
  const ref = useRef()

  const [ result, setResult ] = useState({})

  useEffect(() => {
    if (!ref.current) {
      ref.current = swClient
      swClient.onPost = (type, payload) => {
        console.log('.. From server', type, payload)

        if ([ 'onChange' ].includes(type)) {
          swClient.post({ type: 'queueInfo' })
        }

        if (type === 'queueInfo') {
          setResult(payload)
        }
      }
      console.log('--- REF: ', ref)
    }

    console.log('--- USE EFFECT: ', new Date())
  }, [])

  // console.log('--- USE SW: ', ref, swClient)

  return { result }
}

export default useSW
