import * as React from "react"

import Login from "./Login/Login"
import Scanner from "./Scanner/Scanner"
import List from "./List/List"
import Dashboard from "./Dashboard/Dashboard"
import Sw from "./SW/SW"
import Train from "./Train/Train"

import Error from "./Error/Error"

import AdminRouter from "./Admin"
import SettingsRouter from "./Settings"
import Test from "./Test/Test"
import ServerImage from "./ServerImage/ServerImage"

const pages = [
  { index: true, element: <Login />, publicOnly: true },
  {
    index: true,
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
    public: true,
  },
  {
    path: "/test",
    element: <Test />,
    public: true,
    role: "admin",
  },
  {
    path: "/image",
    element: <ServerImage />,
    public: true,
  },
  {
    path: "/scanner",
    element: <Scanner />,
    // role: "admin",
  },
  {
    path: "/train",
    element: <Train />,
    // role: "admin",
  },
  {
    path: "/list",
    element: <List />,
  },
  {
    path: "/sw",
    element: <Sw />,
    role: "admin",
  },
  AdminRouter,
  SettingsRouter,
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "*",
    element: <Login />,
    publicOnly: true,
  },
  {
    path: "*",
    element: <Dashboard />,
  },
]
export default pages
