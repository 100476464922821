import { useState } from "react"

import ws from "../../services/ws.service"

import "./Test.scss"

const timer = {
  "00:30": 30,
  "8:00": 20,
  "02:30": 55,
  "12:00": 30,
  "01:00": 60,
  "18:00": 60,
  "00:00": 20,
}

const Test = () => {
  const [last, setLast] = useState("none")

  ws.onPost = (payload) => {
    setLast(payload)
  }

  const init = () => {
    ws.send({ type: "start" })
  }

  const debug = () => {
    ws.send({ type: "debug" })
  }

  const listen = () => {
    ws.send({ type: "listen" })
  }

  const sendRandom = () => {
    ws.send({ type: "random", value: Math.random() })
  }

  const countTime = () => {
    const timeNow = new Date()
    const timeLine = Object.entries(timer)
      .map(([time, delay]) => {
        const [h, m] = time.split(":")

        const date = new Date()
        date.setHours(h)
        date.setMinutes(m)
        date.setSeconds(0)
        date.setMilliseconds(0)

        const diff = timeNow - date
        return { date, delay, time, diff }
      })
      .sort((a, b) => {
        return a.diff > b.diff ? 1 : -1
      })

    const nextTime = getNext(timeLine)

    setLast({ timer, timeLine, nextTime })
  }

  const getNext = (timeLine, current) => {
    current = current || timeLine.find((t) => t.diff > 0) || timeLine[0]
    const currentIndex = timeLine.indexOf(current)
    const next = timeLine[currentIndex - 1] || timeLine.at(-1)

    if (!current.delay) {
      return getNext(timeLine, next)
    }

    let playTime = new Date()
    const min = playTime.getMinutes()

    playTime.setMinutes(min + current.delay)

    if (playTime < current.date) {
      playTime = current.date
    }

    console.log(playTime > next.date, { current, playTime, next })

    if (playTime > next.date && current !== next) {
      return next.delay ? next.date : getNext(timeLine, next)
    }

    return playTime
  }

  return (
    <div className='test'>
      <div className='test__nav'>
        <h1>TEST 123</h1>
        <button className='btn warning' onClick={() => init()}>
          Go
        </button>
        <button className='btn cancel' onClick={() => debug()}>
          Debug
        </button>
        <button className='btn cancel' onClick={() => listen()}>
          Listen
        </button>
        <button className='btn' onClick={() => sendRandom()}>
          random
        </button>

        <button className='btn' onClick={() => countTime()}>
          Timer
        </button>
      </div>

      <section>
        <pre>{JSON.stringify(last, null, 2)}</pre>
      </section>
    </div>
  )
}

export default Test
