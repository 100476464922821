import Settings from './Settings'

import Profile from './Profile/Profile'
import Search from './Search/Search'
import PushNotifications from './PushNotifications/PushNotifications'

const SettingsRouter = {
  path: '/settings',
  element: <Settings />,
  children: [
    {
      path: '/settings/profile',
      element: <Profile />,
    },
    {
      path: '/settings/search',
      element: <Search />,
    },
    {
      path: '/settings/push-notifications',
      element: <PushNotifications />,
    },
  ],
}

export default SettingsRouter
