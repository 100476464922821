import { observer } from 'mobx-react'

import Form from 'common/Form/Form'

import auth from 'api/auth'

import './Profile.scss'

const config = [
  {
    type: 'text',
    label: 'First Name',
    name: 'firstName',
  },
  {
    type: 'text',
    label: 'Last Name',
    name: 'lastName',
  },
  {
    type: 'text',
    label: 'Email',
    name: 'email',
  },
  {
    type: 'text',
    label: 'Mobile',
    name: 'mob',
  },
]

const Profile = () => {
  const { user } = auth

  const onSubmit = {
    type: 'submit',
    onSubmit: async (data) => {
      const res = await auth.updateProfile(data)
      console.log(data, res)
    },
  }

  return (
    <div>
      <h1>Profile</h1>
      <div className="profile__form">
        <Form config={[ ...config, onSubmit ]} values={user} />
      </div>
    </div>
  )
}

export default observer(Profile)
