import axios from 'axios'

const api = {
  //-------------------------------------------------------------------------------------------
  getList: (filter) => axios.post(`/products/search`, filter),
  getSearchGroups: () => axios.get(`/products/groups`),
  getUnsavedIds: (list) => axios.post(`/products/unsaved`, list),

  save: (searchId, data) => axios.post(`/products/save/${searchId}`, data),
  update: (id, data) => axios.put(`/products/${id}`, data),
  //-------------------------------------------------------------------------------------------
  getScanHistory: () => axios.get(`/scans`),
  //-------------------------------------------------------------------------------------------
  scanCompleted: (id) => axios.put(`/scans/${id}`, { success: true }),
  //-------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------------
  getFilters: () => axios.get(`/filters`),
  saveFilter: (data) => axios.post(`/filters`, data),
  removeFilter: (id) => axios.delete(`/filters/${id}`),
  updateFilter: (id, data) => axios.put(`/filters/${id}`, data),
}

export default api
