import { useState } from 'react'
import { observer } from 'mobx-react'

import CloseIcon from 'Icons/CloseCircle'

import './Arr.scss'

const Arr = ({ model }) => {
  const { label, value, onChange, children = [], readOnly } = model

  const onRemove = (key) => {
    value.splice(key, 1)
    onChange(value)
  }

  return (
    <div className="input-arr">
      <h4 className="input__label" onDoubleClick={() => console.log(model)}>
        {label}
      </h4>
      <ul>
        {children.map((item, key) => (
          <ArrOne onRemove={() => onRemove(key)} key={key} item={item} readOnly={readOnly} />
        ))}
      </ul>

      {!readOnly && (
        <div className="input-arr__action">
          <button onClick={() => onChange([ ...(value || []), {} ])}>+ Add</button>
        </div>
      )}
    </div>
  )
}

const ArrOne = ({ item, onRemove, readOnly }) => {
  const [ remove, setRemove ] = useState()
  return (
    <li className="input-arr__block">
      {item.render()}
      {!readOnly && (
        <button className="input-arr__remove" onClick={() => setRemove(true)}>
          <CloseIcon />
        </button>
      )}

      {remove && (
        <div className="input-arr__overlay">
          <h3>Sure to remove?</h3>
          <div>
            <button onClick={() => setRemove(false)} className="btn">
              Cancel
            </button>
            <button
              className="btn error"
              onClick={() => {
                setRemove(false)
                onRemove()
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </li>
  )
}

export default observer(Arr)
