const Icon = () => (
	<svg viewBox="0 0 290 125" height="45">
		<g>
			<g>
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="m62.2681,6.0684l-56.5804,56.5807l-3.4866,-3.4866l56.5804,-56.5807l3.4866,3.4866z"
				/>
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="m58.86,2.5033l56.5807,56.5804l-3.4867,3.4866l-56.5806,-56.5804l3.4866,-3.4866z"
				/>
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="m77.9406,2.5033l56.5803,56.5804l-3.4866,3.4866l-56.5803,-56.5804l3.4866,-3.4866z"
				/>
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="m45.5574,63.761l11.4481,0l0,-11.4481l-11.4481,0l0,11.4481zm15.582,-14.946l11.4482,0l0,-11.4482l-11.4482,0l0,11.4482zm-15.582,0l11.4481,0l0,-11.4482l-11.4481,0l0,11.4482zm15.582,14.946l11.4482,0l0,-11.4481l-11.4482,0l0,11.4481z"
				/>
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="m97.8466,2.5033l56.5806,56.5804l-3.4869,3.4866l-56.5803,-56.5804l3.4866,-3.4866z"
				/>
			</g>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m162.5689,111.1558l126.3541,0l0,4.9309l-126.3541,0l0,-4.9309z"
			/>
			<g>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m70.6293,75.5492l9.5404,0c3.7571,0 7.1717,1.5353 9.6453,4.0088c2.4735,2.4735 4.0088,5.8881 4.0088,9.6453l0,6.0421c0,3.7571 -1.5353,7.1717 -4.0088,9.6452c-2.4736,2.4732 -5.8878,4.0089 -9.6453,4.0089l-9.5404,0c-3.7575,0 -7.1717,-1.5357 -9.6453,-4.0089c-2.4731,-2.4735 -4.0088,-5.8877 -4.0088,-9.6452l0,-6.0421c0,-3.7575 1.5357,-7.1718 4.0088,-9.6453c2.4736,-2.4735 5.8882,-4.0088 9.6453,-4.0088zm9.5404,5.048l-9.5404,0c-2.3642,0 -4.5158,0.9692 -6.0763,2.5297c-1.5606,1.5609 -2.5298,3.7125 -2.5298,6.0764l0,6.0421c0,2.3639 0.9692,4.5154 2.5298,6.0763c1.5609,1.5606 3.7124,2.5298 6.0763,2.5298l9.5404,0c2.3639,0 4.5154,-0.9692 6.0763,-2.5298c1.5606,-1.5605 2.5298,-3.7121 2.5298,-6.0763l0,-6.0421c0,-2.3643 -0.9692,-4.5158 -2.5298,-6.0764c-1.5605,-1.5605 -3.7121,-2.5297 -6.0763,-2.5297z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m106.226,106.3755l0,2.524l-5.048,0l0,-23.1739l16.4601,0c2.6794,0 5.1143,1.0947 6.8775,2.8579c1.7632,1.7631 2.8579,4.1981 2.8579,6.8775l0,13.4385l-5.048,0l0,-13.4385c0,-1.2865 -0.5286,-2.4584 -1.3788,-3.3086c-0.8502,-0.8502 -2.0221,-1.3788 -3.3086,-1.3788l-11.4121,0l0,15.6019z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m149.7935,103.8515l2.524,0l0,5.048l-9.6233,0c-2.6726,0 -5.1043,-1.0947 -6.8678,-2.8579l-0.01,0c-1.7631,-1.7632 -2.8578,-4.1981 -2.8578,-6.8775l0,-13.4385l5.048,0l0,13.4385c0,1.2865 0.5286,2.4583 1.3788,3.3086l-0.005,0.005c0.8499,0.8481 2.0232,1.3742 3.3133,1.3742l7.0998,-0.0004z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m143.2736,124.2252l-2.524,0l0,-5.048l9.6233,0c1.2898,0 2.4634,-0.5261 3.3133,-1.3741l-0.005,-0.005c0.8502,-0.8502 1.3788,-2.0221 1.3788,-3.3086l0,-28.7642l5.048,0l0,28.7642c0,2.6794 -1.0947,5.1143 -2.8579,6.8775l-0.01,0c-1.7635,1.7632 -4.1952,2.8579 -6.8678,2.8579l-7.0987,0.0003z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m274.7711,106.3755l0,2.524l-5.048,0l0,-33.3503l5.048,0l0,30.8263z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m258.0961,80.5979l-2.524,0l0,-5.048l33.3503,0l0,5.048l-30.8263,0z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m249.0923,106.3755l0,2.524l-2.4865,0l-2.5615,-3.1006l0,-1.6431l0,-28.6066l5.048,0l0,30.8263z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m221.4657,106.3755l0,2.524l-5.048,0l0,-33.3503l5.048,8.1088l0,22.7175z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m190.507,108.8995l-9.0896,-9.0897l-9.0897,9.0897l-7.1378,0l12.6585,-12.6586l-10.5153,-10.5153l7.1379,0l6.9464,6.9464l6.9464,-6.9464l7.1379,0l-10.5154,10.5153l12.6586,12.6586l-7.1379,0z"
				/>
				<path
					fill="#b1b3b4"
					fillRule="evenodd"
					d="m245.3225,108.8995l-28.9048,-28.9049l0,-4.4454l2.6924,0l29.9822,29.9822l0,3.3681l-3.7698,0z"
				/>
			</g>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m2.2005,111.1558l150.1172,0l0,4.9309l-150.1172,0l0,-4.9309z"
			/>
		</g>
	</svg>
)

export default Icon
