import { makeObservable, observable } from 'mobx'
import axios from 'axios'

import http from './http'
import notifications from './notifications'

class Auth {
  user = null

  constructor() {
    makeObservable(this, {
      user: observable,
    })

    if (http.validToken) {
      this.me()
    }
  }

  //-------------------------------------------------------------------------------------------
  async me() {
    this.user = {}
    const response = await axios.get('/me').catch((e) => {
      console.log(e)
      http.setJwt()
    })

    this.user = response
  }

  //-------------------------------------------------------------------------------------------
  getUsers() {
    return axios.get('/users')
  }

  //-------------------------------------------------------------------------------------------
  register(user) {
    user = user || {
      email: 'olesis@gmail.com',
      password: 'asd123',
      firstName: 'Olesis',
      lastName: 'Alesionka',
      role: 'admin',
    }
    return axios.post('/register', user)
  }

  //-------------------------------------------------------------------------------------------
  async login(data) {
    if (data) {
      const { jwt, user } = await axios.post('/login', data)

      if (jwt && user) {
        http.setJwt(jwt)

        this.user = user

        await notifications.subscribe()
        return user
      }
    }
  }

  //-------------------------------------------------------------------------------------------
  async logout() {
    let body
    if (notifications.id) {
      body = { browserId: notifications.id }
    }
    await axios.post('/logout', body)
    http.clearJwt()
    this.user = null
  }

  //-------------------------------------------------------------------------------------------
  delete({ _key }) {
    return axios.delete(`/delete/${_key}`)
  }

  //-------------------------------------------------------------------------------------------
}

const auth = new Auth()
window.auth = auth
export default auth
