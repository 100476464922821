import { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import { timeDistance } from 'common/helper/date'
import usePreload from 'common/hooks/usePreload'
import CloseIcon from 'Icons/Close'

import notificationsApi from 'api/notifications'

const ProfileNotifications = () => {
  const confirmDialog = useRef(null)
  const { result, loading, doUpdate } = usePreload(notificationsApi.getList)

  const { isSubscribed } = notificationsApi
  const isDenied = Notification.permission === 'denied'

  const onSubmit = () => {
    if (isSubscribed) {
      notificationsApi.unsubscribe()
    } else {
      notificationsApi.subscribe()
    }
  }

  return (
    loading || (
      <div>
        <h1>Push notifications</h1>

        <ul className="tbl">
          {result.map((item, key) => (
            <ActiveBrowser
              {...item}
              key={key}
              onChange={() => {
                doUpdate()
              }}
            />
          ))}
        </ul>

        {isDenied ? (
          <div className="profile__actions">Notifications are blocked by you!</div>
        ) : (
          <div className="profile__actions">
            {isSubscribed === false && (
              <button className="btn" onClick={() => notificationsApi.subscribe()}>
                Subscibe
              </button>
            )}
            {isSubscribed === true && (
              <button className="btn error" onClick={() => confirmDialog.current.showModal()}>
                Unsubscribe
              </button>
            )}
          </div>
        )}

        <dialog ref={confirmDialog}>
          <div className="dialog">
            <h3>Are you sure?</h3>
            <form method="dialog">
              <button>Cancel</button>
              <button className="btn" onClick={onSubmit}>
                {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
              </button>
            </form>
          </div>
        </dialog>
      </div>
    )
  )
}

const ActiveBrowser = ({ userAgent, createdAt, browserId, onChange }) => {
  const { id } = notificationsApi
  const [ confirm, setConfirm ] = useState()
  return (
    <li className={cn('tbl__item', { active: browserId === id })}>
      <div className="--title">
        <div>
          <b>{userAgent.browser}</b> ({userAgent.os})
        </div>
        <time>{timeDistance(createdAt, 'ago')}</time>
      </div>
      <div className="--actions">
        <small>{browserId}</small>
        <button className="btn icon" onClick={() => setConfirm(true)}>
          <CloseIcon />
        </button>

        {confirm && (
          <div className="--confirm-overlay">
            <h3>Sure to remove?</h3>
            <button className="btn cancel" onClick={() => setConfirm(false)}>
              Cancel
            </button>
            <button
              className="btn error"
              onClick={async () => {
                await notificationsApi.remove(browserId)
                setConfirm(false)
                onChange()
              }}
            >
              Delete
            </button>
          </div>
        )}
      </div>
    </li>
  )
}

export default observer(ProfileNotifications)
