import { useState } from "react"
import cn from "classnames"

import DownIcon from "Icons/Down"

const FilterCategory = ({ values, title, name, open, filter, categories, updateFilter }) => {
  const getCount = (name, item) => (categories && categories[name] && categories[name][item.label || "Privatus"]) || 0

  const [isOpen, setIsOpen] = useState(open)

  return (
    <ul className='filter__section'>
      <div className={cn("filter__title", { open: isOpen })} onClick={() => setIsOpen(!isOpen)}>
        <DownIcon /> {title}
      </div>
      {isOpen &&
        values.map((item, key) => (
          <li
            key={key}
            className={cn("filter__item", {
              active: (filter[name] || []).includes(item.label),
              empty: !getCount(name, item),
            })}
          >
            <div className='--checkbox' onClick={() => updateFilter(name, item)}>
              O
            </div>
            <div className='--title' onClick={() => updateFilter(name, item, true)}>
              {item.label || "Privatus"}
              <small>
                {getCount(name, item)} / {item.count}
              </small>
            </div>
          </li>
        ))}
    </ul>
  )
}

export default FilterCategory
