import { useState } from "react"
import cn from "classnames"

import MoreIcon from "Icons/More"

import "./DropDown.scss"

const DropDown = ({ children, options, label }) => {
  const [open, setOpen] = useState()

  return (
    <div className='drop-down'>
      <div className={cn("drop-down__title", { "--label": label })} onClick={() => setOpen(!open)}>
        {children || label} {!children && <MoreIcon />}
      </div>

      {open && (
        <div className='drop-down__content' onClick={() => setOpen(false)}>
          <ul>
            {options.map((item, key) => (
              <li className={item.className} key={key} onClick={() => item.onClick(item)}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default DropDown
