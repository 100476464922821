import { Link, useMatch, useResolvedPath } from "react-router-dom"
import cn from "classnames"

import LogoIcon from "Icons/Logo"
import UserProfile from "./UserProfile/UserProfile"
import QueueInfo from "./QueueInfo/QueueInfo"

import auth from "api/auth"

const nav = [
  {
    path: "/",
    label: "Login",
    public: true,
  },
  // {
  //   path: "/image?aaa=bbb",
  //   label: "Image",
  // },
  // {
  //   path: "/test",
  //   label: "Test",
  //   role: "admin",
  // },
  {
    path: "/list",
    label: "Properties",
  },
  // {
  //   path: "/sw",
  //   label: "SW",
  //   role: "admin",
  // },
  // {
  //   path: "/train",
  //   label: "Train",
  //   role: "admin",
  // },
  // {
  //   path: "/scanner",
  //   label: "Scanner",
  //   role: "admin",
  // },
]

const CustomLink = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to)
  const active = useMatch({ path: resolved.pathname, end: true })

  return (
    <li className={cn({ active })}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}

const Header = () => {
  const { user } = auth

  const { role = [] } = user || {}

  const navLinks = user
    ? nav.filter((item) => !item.public && (!item.role || role.includes(item.role)))
    : nav.filter((item) => item.public)

  return (
    <header className='layout__header'>
      <div className='container'>
        <div className='layout__logo'>
          <Link to='/'>
            <LogoIcon />
          </Link>
        </div>

        <QueueInfo />
        <nav>
          <ul className='nav__menu'>
            {navLinks.map(({ path, label }, key) => (
              <CustomLink key={key} to={path}>
                {label}
              </CustomLink>
            ))}
          </ul>
        </nav>

        <UserProfile />
      </div>
    </header>
  )
}

export default Header
