import { Outlet } from 'react-router-dom'

import Header from './Header/Header'

import './Layout.scss'
import './Layout.page.scss'

const Layout = () => {
  return (
    <div className="layout">
      <Header />

      <main className="layout__content">
        <div className="container">
          <Outlet />
        </div>
      </main>

      <footer className="layout__footer">
        <div className="container">Footer</div>
      </footer>
    </div>
  )
}

export default Layout
