import swClient from 'ServiceWorker/ServiceWorker.client'

import CloseIcon from 'Icons/Close'
import MoreIcon from 'Icons/More'

import TimeCountDown from './TimeCountDown'

const Job = ({ item }) => {
  return (
    <li key={item.id}>
      <button className="btn error small round" onClick={() => swClient.post({ type: 'removeJob', payload: item })}>
        <CloseIcon />
      </button>
      <button className="btn small round" onClick={() => swClient.post({ type: 'jobHistory', payload: item })}>
        <MoreIcon />
      </button>
      <small>{item.id}</small> <b>{item.count}</b> {item.data.label} - <div state={item.state}>{item.state}</div>{' '}
      <TimeCountDown item={item} />
    </li>
  )
}

export default Job
