import cn from "classnames"
import usePreload from "common/hooks/usePreload"

import { timeDistance } from "common/helper/date"
import productsApi from "api/products"

import "./History.scss"

const History = () => {
  const { loading, list } = usePreload(productsApi.getScanHistory)

  return (
    loading || (
      <div className='scans'>
        <h3>Scans History</h3>

        <ul>
          {list.map(({ createdAt, totalSaved, totalFound, duration, search, website, _key }) => (
            <li className={cn("scans__item", { found: totalSaved })} key={_key}>
              <div>
                <img className='scans__logo' src={website.logoUrl} alt='' />
              </div>
              <header>
                <h4>{search.title}</h4>
                <time>{timeDistance(createdAt, "ago")}</time>
                <small> {_key}</small>
              </header>
              <div>
                <div>
                  <small>{totalFound}</small> / <b>{totalSaved}</b>
                </div>
                <time>{Math.round(duration / 1000)} sec</time>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  )
}

export default History
