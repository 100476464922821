import { observer } from "mobx-react"

import queueService from "services/queue.service"

import QueueOne from "./Queue.one"
// import Job from "Pages/Train/Job/Job"

import "./Queue.scss"

const Scanner = () => {
  return (
    <div className='queue2'>
      {!!queueService.list.length && (
        <div className='queue2__content'>
          <ul className='queue2__list'>
            <h4>Preparing</h4>
            {queueService.list
              .filter((item) => !item.state)
              .map((item, key) => (
                <QueueOne key={key} {...item} />
              ))}
          </ul>
          <ul className='queue2__list'>
            <h4>Queue</h4>
            {queueService.waiting.map((item, key) => (
              <QueueOne key={key} {...item} />
            ))}
          </ul>
          <ul className='queue2__list'>
            <h4>Completed ({queueService.count})</h4>
            {queueService.done.map((item, key) => (
              <QueueOne key={key} {...item} />
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default observer(Scanner)
