import axios from 'axios'
import { makeObservable, observable, runInAction } from 'mobx'

const mock = {
  endpoint:
    'https://fcm.googleapis.com/fcm/send/e6EvnH4QBQU:APA91bF3riuF94lpuBY026H3dtZaA1-0jDit-uy-DjGRT0H2_gLvqzrl7QPw4HqV2NvqynArbYzJaa5Anx9W0ZzyJyyEY89YMQeOeXqef2Oy10oR_w3uQqHAJj4tgq4TaR25tIT2sSPb',
  keys: {
    p256dh: 'BDT7clyDwJribinTZLjern5-YMn6NvsaMzK9xO9ut9sXQhDW0QqnBCiL62o1HSClWtajyhev23Gcb8LVYfWcBWc',
    auth: 'M25DbaUFPF4oemEJZdE-PQ',
  },
}

class PushNotifications {
  title = 'Onyx'
  isSubscribed = null

  constructor() {
    this.getState()

    window.notificationsApi = this
    makeObservable(this, { title: observable, isSubscribed: observable })
  }

  getPushManager = async () => {
    if (navigator && navigator.serviceWorker && navigator.serviceWorker.controller) {
      const sw = await navigator.serviceWorker.ready
      return sw.pushManager
    } else {
      return {
        subscribe: () => ({ toJSON: () => mock }),
        getSubscription: () => mock,
      }
    }
  }

  subscribe = async () => {
    const pushManager = await this.getPushManager()
    const pushAuth = await pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: 'BKWNxOMzAaJwGdxf0vMrh_EyGyWK0DWANWyVA-afjM_I1rQf72sCkslp6Vpe8cMFb2ovRrTgj-SuixeCmm33jPI',
    })

    if (pushAuth) {
      console.log(pushAuth.toJSON())
      const json = pushAuth.toJSON()
      this.save(json)
    }
  }

  unsubscribe = async () => {
    const subscription = await this.getSubscription()
    if (subscription) {
      const json = await subscription.unsubscribe()
      this.remove(json)
    }
  }

  getSubscription = async () => {
    const pushManager = await this.getPushManager()
    const subscription = await pushManager.getSubscription()
    return subscription && subscription.toJSON ? subscription.toJSON() : subscription
  }

  getState = async () => {
    const subscription = await this.getSubscription()
    runInAction(() => {
      this.isSubscribed = !!subscription
      this.id = subscription && subscription.keys.p256dh
    })
    return !!subscription
  }

  save = async (body) => {
    await axios.post('/devices', body)
    this.getState()
  }

  remove = async (browserId) => {
    await axios.delete(`/devices/${browserId}`)
    this.getState()
  }

  getList = () => axios.get('/devices')
}

export default new PushNotifications()
