import { observer } from "mobx-react"

import auth from "api/auth"

import LogoIcon from "Icons/Logo"

import "./Dashboard.scss"

const Dashboard = () => {
  const { displayName, email } = auth.user

  return (
    <div className='login'>
      <section className='login__box'>
        <aside className='login__aside'>
          <LogoIcon />
        </aside>
        <article className='login__content'>
          <h1>Welcome!</h1>
          <h3>{displayName}</h3>

          <p>{email}</p>

          <pre>{JSON.stringify(process.env, null, 2)}</pre>
          <div className='desc'>Crawler &copy;2022</div>
        </article>
      </section>
    </div>
  )
}

export default observer(Dashboard)
