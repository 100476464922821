const Icon = () => {
  return (
    <svg height='24' viewBox='0 0 24 24' width='24'>
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M16.01 11H4v2h12.01v3L20 12l-3.99-4z' fill='currentColor' />
    </svg>
  )
}

export default Icon
