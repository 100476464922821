import { observer } from 'mobx-react'

import usePreload from 'common/hooks/usePreload'
import ToggleButton from 'common/ToggleButton/ToggleButton'

import productsApi from 'api/products'

import './Search.scss'

const Search = () => {
  const { result, loading, doUpdate } = usePreload(productsApi.getFilters)

  return (
    loading || (
      <div>
        <h1>Saved Search</h1>

        <ul className="tbl">
          {result.map(({ groups = {}, _key, pushNotifications, email }, key) => (
            <li key={key} className="tbl__item">
              <div>
                {Object.entries(groups).map(([ name, val ]) => (
                  <div key={name} className="--category">
                    <span>{name}: </span>
                    <b>{val.join(', ') || 'Privatus'}</b>
                  </div>
                ))}
              </div>

              <div className="tbl__actions">
                <button
                  onClick={async () => {
                    await productsApi.removeFilter(_key)
                    doUpdate()
                  }}
                >
                  X
                </button>

                <ToggleButton
                  label="Notification"
                  value={pushNotifications}
                  onChange={async () => {
                    await productsApi.updateFilter(_key, { pushNotifications: !pushNotifications })
                    doUpdate()
                  }}
                />
                <ToggleButton
                  label="Email"
                  value={email}
                  onChange={async () => {
                    await productsApi.updateFilter(_key, { email: !email })
                    doUpdate()
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  )
}

export default observer(Search)
