import RightIcon from "Icons/Right2"

import "./History.scss"

const dFormat = (value) =>
  value && new Intl.DateTimeFormat("lt-LT", { dateStyle: "long", timeStyle: "short" }).format(new Date(value))

const ProductHistory = ({ full, current, next }) => {
  const { createdAt, updatedAt, url, ...rest } = full
  const values = Object.entries(JSON.parse(JSON.stringify(rest)))
    .map(([name, item]) => ({
      ...item,
      name,
      current: current[name],
      next: next ? next[name] : item.value,
    }))
    .filter((item) => item.current && item.current !== item.next)

  return current.completedAt && values.length ? (
    <div className='history'>
      <div className='history__time'>
        <time>{dFormat(current.completedAt)}</time>
      </div>
      {values.map((item) => (
        <div key={item.label} className='history__item'>
          <b>{item.label}:</b> {item.name === "photo" ? <img src={item.current} /> : item.current}
          <span>
            <RightIcon />
          </span>
          {item.name === "photo" ? <img src={item.next} /> : item.next}
        </div>
      ))}

      {/* <pre>{JSON.stringify(next, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(current, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    </div>
  ) : null
}

export default ProductHistory
