import { useState, useEffect, useRef } from "react"
import cn from "classnames"

import ws from "services/ws.service"

import PlayIcon from "Icons/Play"
import PauseIcon from "Icons/Pause"
import RemoveIcon from "Icons/Close"

import "./Job.scss"

const div = (a, b) => (a - (a % b)) / b

const countTime = (endTime, delay) => {
  const remain = Math.round((new Date(endTime) - new Date()) / 1000)
  const HOUR = 60 * 60

  const h = div(remain, HOUR)
  const min = div(remain % HOUR, 60)
  const sec = remain % 60

  const perc = Math.min(((delay - remain) / delay) * 100, 100)

  const time = [
    [h, "h"],
    [min, "min"],
    [sec, "sec"],
  ]
    .filter(([value]) => value)
    .map(([value, label]) => `${value} ${label}`)
    .join(" ")

  return { time, perc }
}

const Job = ({
  label,
  data: { logoUrl = "https://static.aruodas.lt/static/svg/aruodo_logo.svg" } = {},
  result,
  pending,
  status,
  children,
  id,
}) => {
  const { total, totalNew, count = 0, duration } = result || {}

  const isCompleted = status === "completed"
  const list = ["completed", "failed"].includes(status) ? result.children : children

  const [time, setTime] = useState()
  const [perc, setPerc] = useState()
  const [showNav, setShowNav] = useState()
  const el = useRef()

  const { endTime, delay, isActive } = pending

  useEffect(() => {
    el.current.oncontextmenu = (e) => {
      e.preventDefault()
      console.log(123, e)
      setShowNav(true)
    }

    const updateTime = () => {
      if (isActive) {
        const { time, perc } = countTime(endTime, delay)

        setTime(time)
        setPerc(perc)
      }
    }

    updateTime()

    const intervalId = setInterval(updateTime, 1000)
    return () => clearInterval(intervalId)
  }, [isActive, endTime, delay, children])

  const pauseJob = () => {
    ws.send({ type: "job/pending/pause", id })
    setShowNav(false)
  }

  const resumeJob = () => {
    ws.send({ type: "job/pending/resume", id })
    setShowNav(false)
  }

  const cancelJob = () => {
    ws.send({ type: "job/cancel", id })
    setShowNav(false)
  }

  const overrideJob = () => {
    ws.send({ type: "job/pending/override", id })
    setShowNav(false)
  }

  return (
    <li className={cn("job", status, { totalNew, selected: showNav })}>
      <div className='job__task' ref={el}>
        <div className='job__count'>{count}</div>
        {logoUrl && <img src={logoUrl} className='job__logo' alt='Site Logo' />}
        <div className='job__title'>
          {showNav && (
            <nav>
              <div className='nav__bg' onClick={() => setShowNav(false)}></div>
              <ul>
                <li>
                  <button className='btn small error' onClick={() => cancelJob()}>
                    <RemoveIcon /> Remove
                  </button>
                </li>
                <li>
                  {pending.isActive ? (
                    <button className='btn small warning' onClick={() => pauseJob()}>
                      <PauseIcon /> Pause
                    </button>
                  ) : (
                    <button className='btn small' onClick={() => resumeJob()}>
                      <PlayIcon /> Resume
                    </button>
                  )}
                </li>
                <li>
                  <button className='btn small' onClick={() => overrideJob()}>
                    <PlayIcon /> Scan now
                  </button>
                </li>
              </ul>
            </nav>
          )}

          <h5>{label}</h5>
          {isCompleted ? (
            <div className='desc'>{total && `${total} / ${totalNew}`}</div>
          ) : (
            <div className='desc'>{time}</div>
          )}
        </div>

        <div className='job__state'>
          {isCompleted ? <div className='job__completed'>{Math.round(duration / 1000)} s</div> : status}
        </div>

        {status === "pending" && <div className='job__timer' style={{ "--done": perc + "%" }} />}
      </div>

      {list && !!list.length && (
        <ul className='job__children'>
          {list.map((item, key) => (
            <li key={key} className={item.status}>
              <a rel='noreferrer' target='_blank' href={item.url}>
                {item.title || item.address}
              </a>
              {item.status === "failed" && (
                <a rel='noreferrer' target='_blank' href={"#/image?url=" + btoa(item.url)}>
                  [Fix]
                </a>
              )}

              {item.status === "failed" && (
                <a rel='noreferrer' target='_blank' href={"http://localhost:8000/onyx/v1/image/" + item.screenshot}>
                  [Preview]
                </a>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}

export default Job
