import { useState } from "react"
import cn from "classnames"

import usePreload from "common/hooks/usePreload"
import DropDown from "common/DropDown/DropDown"
import CloseIcon from "Icons/Close"

import productApi from "api/products"

import ListOne from "./One/List.one"
import ListFilter from "./Filter/List.filter"
import ListPagging from "./Pagging/List.pagging"

import "./List.scss"

const dFormat = (value) => value && new Intl.DateTimeFormat("lt-LT", { dateStyle: "long" }).format(new Date(value))

const List = () => {
  const [filter, setFilter] = useState({ city: ["Kaunas"], contactType: [null] })
  const [currentPage, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [debug, setDebug] = useState()

  const perPage = 15

  const { loading, updating, results, websites, count, page, categories, doUpdate, q } = usePreload(
    productApi.getList,
    {
      filter,
      page: currentPage,
      perPage,
    }
  )

  const haveFilter = !!Object.keys(filter).length

  return (
    <div className='list'>
      <header className='list__header'>
        <h1>List</h1>
        {haveFilter && (
          <DropDown
            options={[
              {
                label: "Save",
                onClick: () => productApi.saveFilter(filter),
              },
              { label: "Reset", className: "error", onClick: () => setFilter({}) },
              { label: "Debug Q", onClick: () => setDebug(q) },
            ]}
          />
        )}
      </header>

      {debug && <pre onDoubleClick={() => setDebug(null)}>{debug}</pre>}

      <div className='list__content'>
        <aside className={cn("list__aside", { open })}>
          <div className='list__aside__bg list__mobile' onClick={() => setOpen(false)} />
          <div className='list__aside__close list__mobile'>
            <button className='btn' onClick={() => setOpen(false)}>
              <CloseIcon />
            </button>
          </div>

          <ListFilter
            filter={filter}
            categories={categories}
            onChange={(v) => {
              setFilter(v)
              setOpen(false)
              doUpdate()
            }}
          />
        </aside>
        {loading || (
          <section className='list__section'>
            {updating}
            <div className='list__head'>
              <button className='list__mobile btn' onClick={() => setOpen(true)}>
                Filter
              </button>
              <div>
                Total:{" "}
                <small>
                  {results.length} of {count}
                </small>
              </div>
            </div>
            {results.map((item, key) => (
              <div key={item._key}>
                <DateHeader current={item} prev={results[key - 1]} />
                <ListOne data={item} schema={websites[item.website._key]} />
              </div>
            ))}

            <ListPagging
              count={count}
              page={page}
              perPage={perPage}
              onChange={(page) => {
                setPage(page)
                doUpdate({ filter, page, perPage })
              }}
            />
          </section>
        )}
      </div>
    </div>
  )
}

const DateHeader = ({ current, prev }) => {
  const currentDate = dFormat(current.completedAt)
  const prevDate = prev && dFormat(prev.completedAt)

  return prevDate && currentDate !== prevDate && <div className='date-header'>{currentDate}</div>
}

export default List
