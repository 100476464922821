import http from "api/http"

class WS {
  url = http.wsURL
  // url = "wss://olesis.gmt.lt"
  // url = "wss://olesis-api.gmt.lt"

  constructor() {
    this.connect()
  }

  connect() {
    this.socketReady = new Promise((r) => (this.resolve = r))
    this.socket = new WebSocket(this.url)

    this.socket.onopen = (e) => {
      console.log("[open] Connection established")
      this.socket.send(JSON.stringify({ msg: "Welcome fron Client" }))
      this.resolve(true)
    }

    this.socket.onmessage = (event) => {
      try {
        this.onPost(JSON.parse(event.data), event)
      } catch (e) {
        console.log(123, e)
      }
    }

    this.socket.onclose = (event) => {
      if (event.wasClean) {
        console.warn(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`)
      } else {
        console.warn("[close] Connection died")
      }
    }

    this.socket.onerror = (error) => {
      console.warn(`[error]`, error)
    }
  }

  send = async (payload) => {
    await this.socketReady
    this.socket.send(JSON.stringify(payload))
  }

  onPost = (payload, event) => {
    console.log(payload, { event })
  }

  subscribe = () => {
    console.log(".. Subscribe! ..")
    this.send({ type: "subscribe", name: "crawler" })
  }

  unsubscribe = () => {
    console.log(".. Unsubscribe! ..")
    this.send({ type: "unsubscribe", name: "crawler" })
  }
}

export default new WS()
