import { observer } from 'mobx-react'

import usePreload from 'common/hooks/usePreload'
import websites from 'api/websites'

import Schema from './Website/Website'
import Queue from './Queue/Queue'
import History from './History/History'

import './Scanner.scss'

const Scanner = () => {
  const { loading, result } = usePreload(websites.getList)

  return (
    <div className="scanner">
      {loading || <ul className="table">{result.map((item, key) => <Schema key={key} {...item} />)}</ul>}
      <Queue />
      <hr />
      <History />
    </div>
  )
}

export default observer(Scanner)
