import { useState, useEffect } from 'react'

import { timeDistance } from 'common/helper/date'

const TimeCountDown = ({ item }) => {
  const getDiff = () => item.startTime && timeDistance(item.startTime, '', { includeSeconds: true })
  const [ diff, setDiff ] = useState(getDiff())

  useEffect(
    () => {
      setDiff(getDiff())
      const timer = setInterval(() => setDiff(getDiff()), 1000)
      return () => clearInterval(timer)
    },
    [ item.startTime ]
  )

  return <time>{diff}</time>
}

export default TimeCountDown
