import { observer } from "mobx-react"
import cn from "classnames"

import TickIcon from "Icons/Tick"

const QueueItem = ({ title, logoUrl, state, total, duration, _delay, count, repeat, createdAt }) => {
  const isCompleted = state === "completed"
  const d = new Date(createdAt)
  const mins = d.getMinutes()
  d.setMinutes(mins + _delay)

  const h = ("00" + d.getHours()).slice(-2)
  const m = ("00" + d.getMinutes()).slice(-2)
  const s = ("00" + d.getSeconds()).slice(-2)

  const diff = (d.getTime() - new Date().getTime()) / 1000
  return (
    <li className={cn("job2", state, { repeat })}>
      {repeat && <div className='job2__count'>{count}</div>}
      {logoUrl && <img src={logoUrl} className='job2__logo' alt='Site Logo' />}
      <div className='job2__title'>
        <h5>{title}</h5>
        {isCompleted ? (
          <div className='desc'>{total && "Found: " + total}</div>
        ) : (
          <div className='desc'>
            {Math.ceil(diff / 60)} min
            <b>
              {h}:{m}:{s}
            </b>
          </div>
        )}
      </div>

      <div className='job2__state'>
        {isCompleted ? (
          <div className='job2__completed'>
            {duration}ms <TickIcon />
          </div>
        ) : (
          state
        )}
      </div>

      {!state && <div className='job2__timer' style={{ "--delay": diff + "s" }} />}
    </li>
  )
}

export default observer(QueueItem)
