import cn from "classnames"

import "./List.pagging.scss"

const Pagging = ({ count, perPage = 5, page = 1, onChange }) => {
  const pages = count && Array(Math.ceil(count / perPage)).fill(null)
  const pagesTotal = pages.length
  const show = count && count > perPage

  const start = [1]
  const end = [pagesTotal]
  const center = [page - 2, page - 1, page, page + 1, page + 2].filter((item) => item > 0 && item <= pagesTotal)

  return (
    <footer>
      {show && (
        <ul className='pagging'>
          {page > 3 &&
            start.map((item) => (
              <li
                key={item}
                onClick={() => {
                  onChange(item)
                  window.scrollTo({ top: 120, behavior: "smooth" })
                }}
              >
                {item}
              </li>
            ))}
          {page > 3 && <li className='--spacer'>...</li>}

          {center.map((item) => (
            <li
              key={item}
              className={cn({ active: item === page })}
              onClick={() => {
                onChange(item)
                window.scrollTo({ top: 120, behavior: "auto" })
              }}
            >
              {item}
            </li>
          ))}

          {page < pagesTotal - 3 && <li className='--spacer'>...</li>}
          {page < pagesTotal - 3 &&
            end.map((item) => (
              <li
                key={item}
                onClick={() => {
                  onChange(item)
                  window.scrollTo({ top: 120, behavior: "smooth" })
                }}
              >
                {item}
              </li>
            ))}
        </ul>
      )}
    </footer>
  )
}

export default Pagging
