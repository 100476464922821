import { observer } from 'mobx-react'

import './Section.scss'

const Section = ({ model }) => {
  const { label, children = [] } = model

  return (
    <div className="input-section">
      {label && (
        <h4 className="input__label" onDoubleClick={() => console.log(model)}>
          {label}
        </h4>
      )}
      <ul>{children.map((item) => item.render())}</ul>
    </div>
  )
}

export default observer(Section)
