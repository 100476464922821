import { useState, useEffect } from "react"

import ws from "services/ws.service"
import websitesApi from "api/websites"
import usePreload from "common/hooks/usePreload"

import Job from "./Job/Job"

import PlayIcon from "Icons/Play"
import PauseIcon from "Icons/Pause"
import StopIcon from "Icons/Stop"

import "./Train.scss"

const Train = () => {
  const { loading, result } = usePreload(websitesApi.getList)

  ws.onPost = (payload) => {
    const { id, inQueue, inRoadmap, inCompleted, active, type } = payload
    console.log(id, type, payload)

    const list = [...inRoadmap, ...inQueue]
    if (active) list.push(active)

    const items = list.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})

    console.log(123, { list, items })

    setItems(items)
    setQueue([...inQueue])
    setActive(active)
    setCompleted([...inCompleted])
    setRoadmap([...inRoadmap])
  }

  const [items, setItems] = useState({})

  const [roadmap, setRoadmap] = useState([])
  const [queue, setQueue] = useState([])
  const [active, setActive] = useState(null)
  const [completed, setCompleted] = useState([])

  useEffect(() => {
    ws.subscribe("crawler")
    return () => ws.unsubscribe("crawler")
  }, [])

  const addJob = (item) => {
    ws.send({ type: "queue/add", item })
  }

  const cancelJob = ({ id }) => {
    ws.send({ type: "job/cancel", id })
  }

  const pause = ({ id }) => {
    ws.send({ type: "queue/pause", id })
  }

  const pauseAll = ({ id }) => {
    ws.send({ type: "queue/pauseAll", id })
  }

  const resume = ({ id }) => {
    ws.send({ type: "queue/resume", id })
  }

  const getItem = ({ _key }) => {
    return items[_key] || {}
  }

  return (
    <div>
      <h1>Depo</h1>

      {loading || (
        <div className='top-items'>
          {result.map(({ _key: websiteKey, search, logoUrl, title }, key) => (
            <ul key={key} className='depo'>
              <img src={logoUrl} alt={title} />
              {search.map((item, key) => (
                <li key={key} className='depo__item'>
                  {items[item._key] ? (
                    <button className='btn warning small' onClick={() => cancelJob(items[item._key])}>
                      <StopIcon />
                    </button>
                  ) : (
                    <button className='btn small' onClick={() => addJob({ ...item, websiteKey, logoUrl })}>
                      <PlayIcon />
                    </button>
                  )}
                  <div>
                    <h4>{item.title}</h4>
                    <div className='top-items__info-line'>
                      <time>{JSON.stringify(item.timer)} min</time>
                      <b>{getItem(item).status}</b>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ))}
        </div>
      )}

      <h1>Train</h1>

      <div className='debug'>
        <button className='btn small cancel' onClick={() => pauseAll()}>
          <PauseIcon /> Pause all
        </button>
        <button className='btn small' onClick={() => pause()}>
          <PauseIcon /> Pause
        </button>
        <button className='btn small' onClick={() => resume()}>
          <PlayIcon /> Resume
        </button>
      </div>

      <div className='trains'>
        <div>
          <h3>Roadmap</h3>

          <ul className='q'>
            {roadmap.map((item) => (
              <Job key={item.id} {...item} />
            ))}
          </ul>
        </div>
        <div>
          <h3>Queue</h3>

          <ul className='q'>
            {queue.map((item) => (
              <Job key={item.id} {...item} />
            ))}
          </ul>

          <h3>In progress</h3>
          <ul className='q'>{active && <Job {...active} />}</ul>
        </div>

        <div>
          <h3>Completed</h3>

          <ul className='q'>
            {completed.map((item, key) => (
              <Job key={key} {...item} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Train
