import axios from 'axios'

const api = {
  getList: () => axios.get(`/websites`),

  //-------------------------------------------------------------------------------------------
  updateSearch: (id, data) => axios.post(`/websites/${id}/search`, data),

  //-------------------------------------------------------------------------------------------
}

export default api
