import React from "react"
import { createRoot } from "react-dom/client"

import { useRoutes, HashRouter as Router } from "react-router-dom"
import { observer } from "mobx-react"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import Layout from "./Layout/Layout"

import pages from "./Pages"
import auth from "api/auth"

const App = observer(() => {
  const { user } = auth
  const { role = [] } = user || {}

  const filterPublic = (arr) => arr.filter((item) => item.public || item.publicOnly)

  const filterPrivate = (arr) =>
    arr.filter((item) => (!item.role && !item.publicOnly) || (item.role && role.includes(item.role)))

  const routes = [
    {
      path: "/",
      element: <Layout />,
      children: user ? filterPrivate(pages) : filterPublic(pages),
    },
  ]

  const element = useRoutes(routes)
  return element
})

const root = createRoot(document.getElementById("root")) // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
serviceWorkerRegistration.register()
