import { useState } from 'react'

import useSW from 'common/hooks/useSW'

import swClient from 'ServiceWorker/ServiceWorker.client'

import Job from './Job'

import './SW.scss'

const websites = [
  {
    title: 'Aruodas',
    label: 'Kaunas',
    delay: 10,
  },
  {
    title: 'Aruodas',
    label: 'Vilnius',
    delay: 30,
  },
  {
    title: 'Aruodas',
    label: 'Klaipeda',
    delay: 100,
  },
]

const Sw = () => {
  const [ count, setCount ] = useState(0)

  const { result } = useSW()
  const { list2 = [], waitingList = [], inProgressList = [], running } = result

  const list = [ ...waitingList, ...inProgressList, running ].filter(Boolean)

  return (
    <div className="page sw">
      <article className="page__content">
        <h1 onClick={() => setCount(count + 1)}>Websites {count}</h1>

        <ul className="sw__list">
          {websites.map((item, key) => (
            <li key={key}>
              <div>
                <div>{item.label}</div>
                <time>{item.delay}</time>
              </div>
              <button className="btn small" onClick={() => swClient.post({ type: 'addQueue', payload: item })}>
                Run
              </button>
            </li>
          ))}
        </ul>

        <h3>List</h3>
        <ul className="sw__list">{list.map((item) => <Job key={item.id} item={item} />)}</ul>

        <h3>Waiting list</h3>
        <ul className="sw__list">{waitingList.map((item) => <Job key={item.id} item={item} />)}</ul>

        <h3>In progress list</h3>
        <ul className="sw__list">{inProgressList.map((item) => <Job key={item.id} item={item} />)}</ul>

        <h3>Running</h3>
        {running && (
          <ul className="sw__list">
            <Job item={running} />
          </ul>
        )}
      </article>
    </div>
  )
}

export default Sw
