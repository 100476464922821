import { observer } from 'mobx-react'

import queueService from 'services/queue.service'

import './QueueInfo.scss'

window.queueService = queueService
const QueueInfo = () => {
	const { list, waiting, done, jobIds, active, isRunning } = queueService

	const preparing = list.filter((item) => !item.state)
	const activeSites = Object.keys(jobIds)

	const show = activeSites.length || isRunning

	return (
		<div className="queue-info">
			{show && (
				<ul className="queue-info__summary">
					<li>
						Active sites: <span count={activeSites.length} />
					</li>
					<li>
						Preparing: <span count={preparing.length} />
					</li>
					<li>
						Queue: <span count={waiting.length} />
					</li>
					<li>
						Completed: <span count={done.length} />
					</li>

					<div className="queue-info__active">
						{active && (
							<div>
								{active.logoUrl && <img src={active.logoUrl} alt="Site logo" />} {active.title}
							</div>
						)}
					</div>
				</ul>
			)}
		</div>
	)
}

export default observer(QueueInfo)
