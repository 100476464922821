import './Error.scss'

const Error = () => {
  return (
    <div className="error-page">
      <h1>Error</h1>
    </div>
  )
}

export default Error
