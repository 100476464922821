import usePreload from "common/hooks/usePreload"
import productApi from "api/products"

import FilterCategory from "./List.filter.category"

import "./List.filter.scss"

const Filter = ({ filter, categories, onChange }) => {
  const { loading, result = {} } = usePreload(productApi.getSearchGroups)

  const { category, city, contactType, buildingType, heatingType, buildingState, roomNr, websiteTitle, wasUpdated } =
    result

  const cat = [
    { title: "Website", values: websiteTitle, name: "websiteTitle", open: true },
    { title: "Category", values: category, name: "category", open: true },
    { title: "City", values: city, name: "city", open: true },
    { title: "Contact Type", values: contactType, name: "contactType", open: true },
    { title: "Room nr", values: roomNr, name: "roomNr" },
    { title: "Building Type", values: buildingType, name: "buildingType" },
    { title: "Heating Type", values: heatingType, name: "heatingType" },
    { title: "Building State", values: buildingState, name: "buildingState" },
    { title: "Modified", values: wasUpdated, name: "wasUpdated" },
  ]

  const updateFilter = (name, { label }, single) => {
    let category = filter[name] || []

    if (category.includes(label)) {
      category = category.filter((v) => v !== label)
    } else {
      if (single) {
        category = [label]
      } else {
        category.push(label)
      }
    }

    if (!category.length) {
      delete filter[name]
    } else {
      filter[name] = category
    }

    onChange({ ...filter })
  }

  return (
    <div className='filter'>
      {loading ||
        cat.map((item) => (
          <FilterCategory
            key={item.name}
            {...item}
            categories={categories}
            filter={filter}
            updateFilter={updateFilter}
          />
        ))}
    </div>
  )
}

export default Filter
