import cn from 'classnames'

import './ToggleButton.scss'

const ToggleButton = ({ label, value, className, disabled, onChange = (f) => f }) => {
  return (
    <div className={cn('toggle-btn', className, { disabled, on: value })} onClick={() => !disabled && onChange(!value)}>
      {label}
      <div className="toggle-btn__btn" />
    </div>
  )
}

export default ToggleButton
