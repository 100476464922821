import { useState } from "react"

import SchemaSearch from "./Website.search"

import MoreIcon from "Icons/More"
import CloseIcon from "Icons/Close"
import DropDown from "common/DropDown/DropDown"
import Form from "common/Form/Form"

import "./Website.scss"

const config = [
  {
    type: "text",
    label: "Title",
    name: "title",
  },
  {
    type: "text",
    label: "Website",
    name: "website",
  },
  {
    type: "text",
    label: "Logo URL",
    name: "logoUrl",
  },

  {
    type: "columns",
    children: [
      {
        type: "section",
        label: "List",
        parentName: "list",
        children: [
          {
            type: "text",
            label: "Selector",
            name: "selector",
          },
          {
            type: "arr",
            name: "results",
            label: "Results",
            children: [
              {
                type: "text",
                label: "Param name",
                name: "name",
              },
              {
                type: "text",
                label: "Label",
                name: "label",
              },
              {
                type: "text",
                label: "Selector",
                name: "selector",
              },
              {
                type: "text",
                label: "Attribute",
                name: "attr",
              },
              {
                type: "text",
                label: "Units",
                name: "units",
              },
              {
                type: "text",
                label: "Type",
                name: "type",
              },
            ],
          },
        ],
      },
      {
        type: "section",
        label: "Details",
        parentName: "details",
        children: [
          {
            type: "text",
            label: "Selector",
            name: "selector",
          },
          {
            type: "arr",
            name: "results",
            label: "Results",
            children: [
              {
                type: "text",
                label: "Param name",
                name: "name",
              },
              {
                type: "text",
                label: "Label",
                name: "label",
              },
              {
                type: "text",
                label: "Selector",
                name: "selector",
              },
              {
                type: "text",
                label: "Value of",
                name: "dataList",
              },
              {
                type: "text",
                label: "Units",
                name: "units",
              },
              {
                type: "text",
                label: "Type",
                name: "type",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    type: "submit",
    onSubmit: (values, model) => console.log(values, model),
  },
]

const tabs = {}

const Schema = (schema) => {
  const [showDetails, setShowDetails] = useState()
  const [readOnly, setReadOnly] = useState(true)
  const [search, setSearch] = useState(schema.search)

  const { title, logoUrl, website, list, details } = schema

  const menu = [
    {
      label: "Edit config",
      onClick: () => setShowDetails(true),
    },
    {
      label: "Create new search",
      onClick: () => {
        setSearch([...search, {}])
      },
    },
  ]

  const listResults = Object.entries(list.results).map(([name, val]) => ({ name, ...val }))
  const detailsResults = Object.entries(details.results).map(([name, val]) => ({ name, ...val }))

  const values = {
    title,
    website,
    logoUrl,
    list: { results: listResults, selector: list.selector },
    details: { results: detailsResults, selector: details.selector },
  }

  const Comp = tabs[showDetails]
  return (
    <li className='website'>
      <header className='website__header'>
        <h2>{title}</h2>
        <div>
          <DropDown options={menu}>
            <MoreIcon />
          </DropDown>
        </div>
      </header>

      {showDetails && (
        <article className='website__form'>
          <div className='website__form__top'>
            <div>{logoUrl ? <img src={logoUrl} alt='Logo' /> : "Logo"}</div>

            <button onClick={() => setReadOnly(!readOnly)}>Edit</button>
            <button onClick={() => setShowDetails(false)}>
              <CloseIcon /> Close
            </button>
          </div>
          {Comp ? (
            <Comp schema={schema} values={values} />
          ) : (
            <Form config={config} values={values} readOnly={readOnly} />
          )}
        </article>
      )}
      {search.map((item, key) => (
        <SchemaSearch key={key} schema={schema} search={item} />
      ))}
    </li>
  )
}

export default Schema
