import { observer } from 'mobx-react'

import './Text.scss'

const Text = ({ model }) => {
  const { label, value, onChange, name, readOnly } = model
  return (
    <div className="input input-text">
      <div className="input__label" onDoubleClick={() => console.log(model)}>
        <label htmlFor={name}>{label || '-- none --'}</label>
      </div>
      <div className="input__value">
        {readOnly ? (
          <div className="input-text__read-only">{value}</div>
        ) : (
          <input id={name} value={value || ''} onChange={({ target }) => onChange(target.value)} />
        )}
      </div>
    </div>
  )
}

export default observer(Text)
