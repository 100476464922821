import { useState } from "react"
import { observer } from "mobx-react"
import cn from "classnames"

import scannerService from "services/scanner.service"
import queueService from "services/queue.service"
import websitesApi from "api/websites"
import productsApi from "api/products"

import DownIcon from "Icons/Down"

import Form from "common/Form/Form"

// import List from "./Website.search.list"
// import Preview from "./Preview/Preview"

const config = [
  {
    type: "columns",
    children: [
      {
        type: "section",
        label: "Search info",
        children: [
          {
            type: "text",
            label: "Title",
            name: "title",
          },
          {
            type: "text",
            label: "Search URL",
            name: "url",
          },
          {
            type: "text",
            label: "Type",
            name: "data.type",
          },
          {
            type: "text",
            label: "Category",
            name: "data.category",
          },
          {
            type: "text",
            label: "City",
            name: "data.city",
          },
        ],
      },
      {
        type: "section",
        label: "Timer",
        children: [
          {
            type: "arr",
            name: "times",
            children: [
              {
                type: "text",
                label: "Time",
                name: "time",
              },
              {
                type: "text",
                label: "Delay in minutes",
                name: "delay",
              },
            ],
          },
        ],
      },
    ],
  },
]

const ScannerSchemaSearch = ({ schema, search }) => {
  const { logoUrl } = schema
  const { jobIds } = queueService
  const id = search._key

  const [searchData] = useState(search)
  const [open, setOpen] = useState(!id)
  const [progress, setProgress] = useState({})
  const [payload, setPayload] = useState({})

  const [activeTab, setActiveTab] = useState("info")

  const { title, url, timer, data } = searchData

  //-----------------------------------------------------------------------------------------------
  const times =
    typeof timer === "string"
      ? [{ delay: timer }]
      : Object.entries(timer || {}).map(([time, delay]) => ({ time, delay }))

  //-----------------------------------------------------------------------------------------------
  const active = jobIds && jobIds[id]

  //-----------------------------------------------------------------------------------------------
  const onUpdate = (state, payload) => {
    setProgress(state)
    setPayload(payload)
  }

  //-----------------------------------------------------------------------------------------------
  const run = async () => {
    if (active) {
      return queueService.stop(active)
    }

    setProgress({})

    const action = async () => {
      const res = await scannerService.getList({ search, schema }, onUpdate)

      const { scanId, totalFound, totalSaved, saved } = res

      if (saved && saved.length) {
        const jobs = saved.map((item) => ({ action: () => scannerService.getOne({ schema, item }), item }))
        const queue = queueService.add(jobs)

        Promise.all(queue).then(async () => {
          await productsApi.scanCompleted(scanId)
        })
      }

      return { totalFound, totalSaved, saved }
    }

    queueService.add({ repeat: true, action, item: search, title, logoUrl, timer })
  }

  //-----------------------------------------------------------------------------------------------
  const values = { id, schemaId: schema.id, title, url, data, times }

  //-----------------------------------------------------------------------------------------------
  const onSubmit = {
    type: "submit",
    onSubmit: async ({ times, ...rest }) => {
      const entries = times.filter((item) => item.time).map(({ time, delay }) => [time, delay || false])

      const timer = entries.length
        ? Object.fromEntries(entries)
        : (times.find((item) => !item.time) || {}).delay || "10"

      const res = await websitesApi.updateSearch(schema._key, { ...rest, timer })

      console.log(555, res)
      // const [ res ] = await db.saveSearch({ ...rest, timer })
      // setSearchData(res)
    },
  }

  //-----------------------------------------------------------------------------------------------
  const onChangeTab = (name) => {
    name = name === activeTab ? false : name
    setActiveTab(name)
  }

  // const tabs = {
  //   incompletedList: () => <List searchId={id} schema={schema} search={search} />,
  //   preview: () => <Preview searchId={id} schema={schema} search={search} />,
  //   info: () => <ScanInfo progress={progress} payload={payload} onStart={run} job={active} />,
  // }

  // const activeComponent = activeTab && tabs[activeTab]
  return (
    <div className={cn("search", { active })}>
      <div>
        <div className='search__row'>
          <div onClick={() => setOpen(!open)}>
            <DownIcon />
          </div>
          <div>
            <img src={logoUrl} className='small' alt='logo' />
          </div>
          <div className='flex-1'>
            <h4>
              [{id}] {title}
            </h4>
          </div>
          <ul className='timer'>
            {times.map((item, key) => (
              <li key={key}>
                {item.time} - {item.delay || "off"}
              </li>
            ))}
          </ul>
          <div />
          <div>
            <a href={url} target='_blank' rel='noreferrer'>
              Open URL
            </a>
          </div>
          {/* <div className='search__status'>
            <input type='checkbox' checked={!!active} onChange={run} /> {active && active.count}
          </div> */}
        </div>
      </div>
      {open && (
        <div className='table__content'>
          <div>
            <div className='flex'>
              <div className='flex-1'>
                <div className='flex'>
                  <div className='flex-1'>
                    <div className='table__form'>
                      <h4>Details</h4>
                      <button
                        className={cn("tabs__item", { active: activeTab === "edit" })}
                        onClick={() => onChangeTab("edit")}
                      >
                        Edit
                      </button>
                    </div>

                    <Form config={[...config, onSubmit]} values={values} readOnly={activeTab !== "edit"} />
                  </div>
                </div>
                {/* <ul className='tabs'>
                  <li className={cn("tabs__item", { active: activeTab === "info" })}>
                    <button onClick={() => onChangeTab("info")}>Scan info</button>
                  </li>
                  <li className={cn("tabs__item", { active: activeTab === "incompletedList" })}>
                    <button onClick={() => onChangeTab("incompletedList")}>Get incomplete list</button>
                  </li>
                  <li className={cn("tabs__item", { active: activeTab === "preview" })}>
                    <button onClick={() => onChangeTab("preview")}>Preview</button>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>

          {/* <div>{activeComponent && activeComponent()}</div> */}
        </div>
      )}
    </div>
  )
}

export default observer(ScannerSchemaSearch)

const ScanInfo = ({ progress, payload = {}, onStart, job }) => {
  const [tab, setTab] = useState("found")
  const { existing } = payload || {}

  return (
    <div>
      <div className='scan-info'>
        <div className='scan-info__progress'>
          <h3>Scan info</h3>

          {progress.started !== undefined ? (
            <ul className='scan-info__summary'>
              <li className='inactive'>Started</li>
              {progress.found !== undefined && (
                <li className={cn({ active: tab === "found" })} onClick={() => setTab("found")}>
                  Found: <span>{progress.found}</span>
                </li>
              )}
              {progress.newItems !== undefined && (
                <li className={cn({ active: tab === "existing" })} onClick={() => setTab("existing")}>
                  Existing: <span>{existing && existing.length}</span>
                </li>
              )}
              {progress.newItems !== undefined && (
                <li className={cn({ active: tab === "unsaved" })} onClick={() => setTab("unsaved")}>
                  New items: <span>{progress.newItems}</span>
                </li>
              )}
              {progress.saved !== undefined && (
                <li className={cn({ active: tab === "saved" })} onClick={() => setTab("saved")}>
                  Saved: <span>{progress.saved}</span>
                </li>
              )}
              {progress.finished && (
                <li className='inactive'>
                  Finished: <span>{progress.finished}ms</span>
                </li>
              )}
            </ul>
          ) : job ? (
            <div>Started...</div>
          ) : (
            <div className='scan-info__idle'>
              Not active... <button onClick={onStart}>Start</button>
            </div>
          )}
        </div>

        {progress.started !== undefined && (
          <div className='scan-info__result'>
            {payload[tab] && payload[tab].map((item, key) => <pre key={key}>{JSON.stringify(item, null, 2)}</pre>)}
          </div>
        )}
      </div>
    </div>
  )
}
