const Icon = () => {
  return (
    <svg height='24' viewBox='0 0 24 24' width='24'>
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M8 5v14l11-7z' fill='currentColor' />
    </svg>
  )
}

export default Icon
