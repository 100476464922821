import { Outlet, NavLink } from "react-router-dom"

import "./Admin.scss"

const Admin = () => {
  const activeClass = ({ isActive }) => (isActive ? "active" : undefined)

  return (
    <div className='page'>
      <aside className='page__aside'>
        <h2>Admin</h2>
        <ul className='page__menu'>
          <li>
            <NavLink className={activeClass} to='/admin/users'>
              Users
            </NavLink>
          </li>
        </ul>
      </aside>
      <article className='page__content'>
        <Outlet />
      </article>
    </div>
  )
}

export default Admin
