import { makeObservable, observable } from 'mobx'

import Job from './job.service'

const SEC = 1000
const MIN = 60 * SEC

//-----------------------------------------------------------------------------------------------
const randomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

//-----------------------------------------------------------------------------------------------
const delay = (ms, to) => {
  if (to) ms = randomInt(ms, to)
  return new Promise((resolve) => setTimeout(() => resolve(ms), ms))
}

class Queue {
  minDelay = MIN

  count = 0
  done = []
  active = null
  waiting = []
  list = []
  isRunning = null

  //-----------------------------------------------------------------------------------------------
  constructor() {
    // window.q = this
    makeObservable(this, {
      list: observable,
      waiting: observable,
      done: observable,
      isRunning: observable,
      jobIds: observable.ref,
    })
  }

  //-----------------------------------------------------------------------------------------------

  jobIds = {}

  add(list, force) {
    const jobs = [].concat(list).filter(({ item }) => force || !(item._key && this.jobIds[item._key])).map((item) => {
      const job = new Job(item)
      if (item.item && item.item._key && item.repeat) {
        this.jobIds[item.item._key] = job
      }
      return job
    })

    this.jobIds = { ...this.jobIds }

    this.list.push(...jobs)

    jobs.forEach((job) => {
      if (!force && job.repeat) {
        job.setState('queue')
        this.waiting.push(job)

        if (!this.isRunning) {
          this.run()
        }
      } else {
        this.setWaiting(job)
      }
    })

    return jobs.map((item) => item.promise)
  }

  //-----------------------------------------------------------------------------------------------
  async setWaiting(job) {
    await delay(job.getDelay() * MIN)

    job.setState('queue')
    this.waiting.push(job)
    if (!this.isRunning) {
      this.run()
    }
  }

  //-----------------------------------------------------------------------------------------------
  async run() {
    this.isRunning = true
    const job = this.waiting.shift()

    if (job) {
      this.active = job
      this.done.unshift(job)
      const { length } = this.done
      this.done.length = Math.min(10, length)
      job.setState('waiting')

      await delay(5 * SEC)

      job.setState('running')

      job.started = new Date()

      try {
        job.res = await this.runJob(job)

        job.setState('completed')

        if (Array.isArray(job.res)) {
          job.setTotal(job.res.length)
        }
      } catch (e) {
        job.error = e
        job.setState('failed')
      }
      job.finished = new Date()
      job.setDuration(job.finished.getTime() - job.started.getTime())
      this.active = null

      if (job.repeat) {
        this.add({ ...job.initial, count: job.count }, true)
      }

      job.resolve(job)
    }

    if (this.waiting && this.waiting.length) {
      this.run()
    } else {
      this.isRunning = false
    }
  }

  async runJob(job) {
    this.count++
    return job.action()
  }

  //-----------------------------------------------------------------------------------------------
  pause(job) {
    job.repeat = false
  }

  //-----------------------------------------------------------------------------------------------
  stop(job) {
    this.list = this.list.filter((item) => item !== job)
    this.waiting = this.waiting.filter((item) => item !== job)
    const { item } = job
    delete this.jobIds[item._key]
    this.jobIds = { ...this.jobIds }
  }

  //-----------------------------------------------------------------------------------------------
}

export default new Queue()
