import { observer } from 'mobx-react'

import './Select.scss'

const Select = ({ model }) => {
  const { label, value, onChange, name, readOnly, options } = model

  return (
    <div className="input input-text">
      <div className="input__label" onDoubleClick={() => console.log(model)}>
        <label htmlFor={name}>{label || '-- none --'}</label>
      </div>
      <div className="input__value">
        {readOnly ? (
          <div className="input-text__read-only">{value}</div>
        ) : (
          <select onChange={({ target }) => onChange(target.value)}>
            {options.map((item, key) => (
              <option key={key} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  )
}

export default observer(Select)
