import React from 'react'

import './Loader.scss'

const Icon = ({ className }) => (
  <div className="cssload-loader">
    <div className="cssload-inner cssload-one" />
    <div className="cssload-inner cssload-two" />
    <div className="cssload-inner cssload-three" />
  </div>
)

export default Icon
