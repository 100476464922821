import axios from "axios"
import Cookies from "js-cookie"

const server = "olesis-api.gmt.lt"
const local = "localhost:8000"

const isProd = process.env.NODE_ENV === "production"
const s = isProd ? "s" : ""

const domain = isProd ? server : local

const http = {
  baseURL: `http${s}://${domain}/onyx/v1`,
  wsURL: `ws${s}://${domain}/`,
  // baseURL: "https://olesis.gmt.lt/onyx/v1",
  // baseURL: "https://olesis-api.gmt.lt/onyx/v1",
  init() {
    axios.defaults.baseURL = this.baseURL
    delete axios.defaults.headers.common["X-Session-Id"]

    const jwt = Cookies.get("JWT")

    this.setJwt(jwt)
    this.setInterceptors()
  },

  setJwt(jwt) {
    if (jwt) {
      try {
        Cookies.set("JWT", jwt)
        axios.defaults.headers.common["X-Session-Id"] = jwt
        this.validToken = true
        return true
      } catch (e) {
        this.clearJwt()
      }
    }
    this.clearJwt()
  },

  clearJwt() {
    this.validToken = false
    delete axios.defaults.headers.common["X-Session-Id"]
    Cookies.remove("JWT")
  },

  setInterceptors: () => {
    axios.interceptors.response.use((response = {}) => {
      const { data } = response
      return data
    })
  },
}

http.init()
export default http
