import { useState } from 'react'
import cn from 'classnames'

import { timeDistance } from 'common/helper/date'

import DeleteIcon from 'Icons/Close'

const labels = {
  label: 'Vartotojas',
  value: 'user',
  broker: 'Brokeris',
  callCenter: 'Centras',
  admin: 'Administratorius',
}

const User = ({ user, onDelete }) => {
  const [ remove, setRemove ] = useState()

  return (
    <tr>
      <td>
        <h4>{user.displayName}</h4>
        <small>{user.email}</small>
      </td>
      <td> {user.mob}</td>
      <td>
        <div className={cn('role', user.role)}>{labels[user.role] || user.role}</div>
      </td>
      <td>
        <time>{timeDistance(user.createdAt, 'ago')}</time>
      </td>
      <td>
        <div className="table__actions">
          <button onClick={() => setRemove(true)}>
            <DeleteIcon />
          </button>
        </div>
        {remove && (
          <div className="table__overlay">
            <h4>Delete user?</h4>
            <div className="flex">
              <button className="btn cancel" onClick={() => setRemove(false)}>
                Cancel
              </button>
              <button className="btn error" onClick={() => onDelete(user)}>
                Delete
              </button>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default User
