import Admin from './Admin'

import Users from './Users/Users'

const AdminRouter = {
  path: '/admin',
  element: <Admin />,
  role: 'admin',
  children: [
    {
      path: '/admin/users',
      element: <Users />,
    },
  ],
}

export default AdminRouter
