import set from 'lodash.set'
import get from 'lodash.get'

import { makeObservable, observable, toJS, action } from 'mobx'

import Text from '../input/Text/Text'
import Select from '../input/Select/Select'

import Submit from '../input/Submit/Submit'
import Arr from '../input/Arr/Arr'
import Section from '../input/Section/Section'
import Columns from '../input/Columns/Columns'

const inputTypes = {
  text: Text,
  submit: Submit,
  arr: Arr,
  section: Section,
  columns: Columns,
  select: Select,
}

class Input {
  //-----------------------------------------------------------------------------------------------
  constructor(config, parent) {
    this._config = config
    this._parent = parent

    this.data = parent.data
    this.readOnly = parent.readOnly

    this._parentName = [ parent.parentName, config.parentName ].filter(Boolean).join('.')

    this.isRoot = !parent._config
    this.uniqueId = ('' + Math.random()).slice(2)

    if (this.isRoot) {
      makeObservable(this, {
        data: observable,
      })
    }

    makeObservable(this, {
      onChange: action,
    })

    if (Array.isArray(config)) {
      return config.map((item) => new Input(item, this))
    }

    this.init()
  }

  //-----------------------------------------------------------------------------------------------
  init() {
    const { children } = this._config

    if (children) {
      this._children = this.getChildren()
    }
  }

  getChildren() {
    const { children, type, name } = this._config

    if (children && type === 'arr') {
      return (this.value || [])
        .map((v, key) => new Input({ key, type: 'section', parentName: name + '.' + key, children }, this))
    }

    if (children) {
      return children.map((item) => new Input(item, this))
    }
  }

  //-----------------------------------------------------------------------------------------------
  onChange = (value) => {
    set(this.data, this.name, value)

    if (this.type === 'arr') {
      this.init()
    }
  }

  //-----------------------------------------------------------------------------------------------
  get config() {
    return this._config || {}
  }

  get type() {
    return this.config.type
  }

  get label() {
    return this.config.label
  }

  get name() {
    const { name } = this.config
    const { parentName } = this

    return parentName ? parentName + '.' + name : name
  }

  get parentName() {
    return this._parentName
  }

  get value() {
    return toJS(get(this.data, this.name))
  }

  get values() {
    return toJS(this.data)
  }

  get children() {
    return this._children
  }

  get options() {
    return this.config.options
  }

  //-----------------------------------------------------------------------------------------------
  render() {
    const Component = inputTypes[this.type] || Text
    return <Component key={this.name || this.uniqueId} model={this} />
  }

  //-----------------------------------------------------------------------------------------------
}

export default Input
