import { useState } from "react"
import { observer } from "mobx-react"
import { Link, useNavigate } from "react-router-dom"
import cn from "classnames"

import auth from "api/auth"

import Spinner from "Icons/spinner/Spinner"

import ManageAccountIcon from "Icons/ManageAccount"
import LogoutIcon from "Icons/Logout"

import "./UserProfile.scss"

const UserProfile = () => {
  const [open, setOpen] = useState()
  const navigate = useNavigate()

  const { user } = auth

  const { email, displayName, role = [] } = user || {}
  const isAdmin = role.includes("admin")

  const links = [
    {
      label: "Settings",
      path: "/settings/search",
      icon: <ManageAccountIcon />,
    },
    {
      label: "Admin",
      path: "/admin/users",
      role: "admin",
    },
    {
      label: "iBrowser",
      path: "/image",
      role: "admin",
    },
    // {
    //   label: "Test",
    //   path: "/test",
    //   role: "admin",
    // },
    {
      label: "Train",
      path: "/train",
      role: "admin",
    },
    {
      label: "Scanner",
      path: "/scanner",
      role: "admin",
    },
  ].filter((item) => !item.role || role.includes(item.role))

  return user ? (
    <div className='user-profile'>
      <div className={cn("user-profile__title", { open })} onClick={() => setOpen(!open)}>
        {email ? displayName : <Spinner />}
      </div>

      {open && (
        <div className='user-profile__content' onClick={() => setOpen(false)}>
          <div className='user-profile__header'>
            <div className={cn("role", role)}>{role}</div>
          </div>
          <ul>
            {links.map((item, key) => (
              <li key={key} className={item.role}>
                <Link to={item.path}>
                  {item.icon} {item.label}
                </Link>
              </li>
            ))}

            <li>
              <button
                onClick={() => {
                  auth.logout()
                  navigate("/")
                }}
              >
                <LogoutIcon /> Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  ) : null
}

export default observer(UserProfile)
