import { timeDistance } from "common/helper/date"

// import EditIcon from "Icons/Down"

import History from "./History/History"

import "./List.one.scss"
import { useState } from "react"

const format = {
  datetime: ({ value }) => {
    return new Intl.DateTimeFormat("lt-LT", { dateStyle: "long", timeStyle: "short" }).format(new Date(value))
  },
  date: ({ value }) => {
    return new Intl.DateTimeFormat("lt-LT", { dateStyle: "long" }).format(new Date(value))
  },
}

const ListOne = ({ data, schema }) => {
  const [showEdit, setShowEdit] = useState(false)
  const full = { ...schema.list.results, ...schema.details.results }
  Object.entries(full).forEach(([name, item]) => {
    item.value = data[name]
  })

  const {
    id,
    price,
    area,
    roomNr,
    url,
    photo,
    yearBuild,
    contactName,
    contactNumber,
    header,
    title,
    pricePerSqr,
    contactType,
    floor,
    totalFloors,
    createdDate,
    updatedDate,
    completedAt,
    ...rest
  } = full

  if (floor && totalFloors) {
    floor.value = floor.value + "/" + totalFloors.value
  }
  const infoList = [id, roomNr, area, yearBuild, floor]
  const contactList = [createdDate, contactType, contactName, contactNumber]
  const restList = Object.values(rest)

  const { history } = data
  return (
    <div className='property'>
      <div className='property__item' onDoubleClick={() => console.log({ data, full, history })}>
        <header className='property__header --header'>
          <h3>
            {/* <button className='blank' onClick={() => setShowEdit(!showEdit)}>
              <EditIcon />
            </button> */}
            <a href={url.value} target='_blank' rel='noreferrer'>
              {(header && header.value) || (title && title.value)}
            </a>
          </h3>
          <div>{data.completedAt && <time>{timeDistance(data.completedAt, "ago")}</time>}</div>
        </header>
        <div className='property__photo --photo'>
          <img src={data.photo} alt={data.address} className='--image' />
          <div className='property__site'>
            <img src={schema.logoUrl} alt={schema.title} />
          </div>
        </div>

        <ul className='property__info --primary'>
          {infoList.map(
            (item, key) =>
              item &&
              item.label &&
              item.value && (
                <li key={key}>
                  <span>{item.label}:</span>
                  {format[item.type] ? format[item.type](item) : item.value} {item.units}
                </li>
              )
          )}
        </ul>

        <ul className='property__info --price'>
          <li className='property__price'>
            <h3>{price && price.value}</h3>
            <span>{pricePerSqr && pricePerSqr.value}</span>
          </li>
        </ul>

        <ul className='property__info --secondary'>
          {restList.map(
            (item, key) =>
              item &&
              item.label &&
              item.value && (
                <li key={key}>
                  <span>{item.label}:</span>
                  {item.value}
                </li>
              )
          )}
        </ul>

        <ul className='property__info --contacts'>
          {contactList.map(
            (item, key) =>
              item &&
              item.label &&
              item.value && (
                <li key={key}>
                  {item.value.split(",").map((text, k) => (
                    <div key={k}>{text.startsWith("+370") ? <a href={"tel:" + text}>{text}</a> : text}</div>
                  ))}
                </li>
              )
          )}
        </ul>
      </div>
      {history &&
        history.map((current, key) => <History key={key} full={full} current={current} next={history[key - 1]} />)}

      {showEdit && <div>EDIT</div>}
    </div>
  )
}

export default ListOne
